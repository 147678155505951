// Pagination.js
import { connect } from 'react-redux';

import {
  PAGINATION_BUTTON_COUNT,
  PAGINATION_BUTTON_COUNT_ON_MOBILE,
} from '../RemoteConfigKeys.js';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import Pagination from '../component/Pagination.jsx';

const mapStateToProps = state => {
  const maxVisibleButtons = getRemoteConfigData(state, PAGINATION_BUTTON_COUNT);
  const maxVisibleButtonsOnMobile = getRemoteConfigData(
    state,
    PAGINATION_BUTTON_COUNT_ON_MOBILE
  );
  return {
    maxVisibleButtons,
    maxVisibleButtonsOnMobile,
  };
};

export default connect(mapStateToProps)(Pagination);
